<template>
  <div>
    <NavBer></NavBer>
    <Title></Title>

    <div class="w contents">
      <div class="jianjie">
        常备药品
      </div>
      <el-row>
        <el-col :span="16">
          <div class="grid-content">
            <el-carousel
              :interval="4000"
              type="card"
              height="450px"
              indicator-position="none"
              @change="changeImg"
            >
              <el-carousel-item v-for="(item, index) in Rotation" :key="index">
                <div class="chart">
                  <router-link to="/overview"> <img :src="item.url" alt="" /></router-link>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content" v-if="index == 0">
            <h3 class="title_biaoti">美沙拉秦缓释颗粒</h3>
            <p class="">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 溃疡性结肠炎，用于溃疡性结肠炎的急性发作，防止复发。
            </p>
            <p class="">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 克罗恩病，用于频繁发发病的克罗恩病病人，预防急性发作。
            </p>
          </div>
          <div class="grid-content" v-if="index == 1">
            <h3 class="title_biaoti">固本益肠片</h3>
            <p class="">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 健脾温肾，涩肠止泻。用于脾肾阳虚所致的泄泻，症见腹痛绵绵、大便清稀或有粘液及粘液血便，食少腹胀，腰酸乏力，形寒肢冷，舌淡苔白，脉虚；慢性肠炎见上述证候者。
            </p>
          </div>
          <div class="grid-content" v-if="index == 2">
            <h3 class="title_biaoti">香砂养胃丸(浓缩丸)</h3>
            <p class="">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 	温中和胃。用于不思饮食，胃脘满闷或泛吐酸水。
            </p>
          </div>
        </el-col>
      </el-row>
      
    </div>

    <Footbar></Footbar>
  </div>
</template>

<script>
import NavBer from "../components/nav";
import Title from "../components/title";
import Footbar from "../components/footbar";
export default {
  components: {
    NavBer,
    Title,
    Footbar,
  },
  data() {
    return {
      Rotation: [
        {
          url: require('../assets/images/product/product1.jpg')
        },
        {
          url: require('../assets/images/product/product2.jpg')
        },
        {
          url: require('../assets/images/product/product3.jpg')
        },
      ],
      index: 0,
    };
  },
  methods: {
    changeImg(index) {
      console.log(index);
      this.index = index;
    },
  },
};
</script>

<style scoped>
.contents {
  margin-top: 60px;
}
.jianjie {
  width: 100px;
  text-align: center;
  margin: 60px auto 40px;
  font-size: 20px;
  border-bottom: 1px solid #333;
}

.el-row {
  margin-bottom: 20px;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 450px;
  overflow: hidden;
   box-shadow: 0px 27px 46px 0px rgba(0, 0, 0, 0.15);
}
.grid-content p {
   width: 240px;
   margin: 80px auto;
   
   
}
.chart img {
  height: 350px;
  width: 100%;
}
.dongtai img {
  width: 100%;
  height: 400px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 350px;
  margin: 0;
}
.newss {
  padding: 10px;
 
}
.xinwen img {
  width: 100%;
  height: 240px;
  margin-bottom: 20px;  
}
.newss span {

  margin:0 20px 0 20px;
  font-size: 14px;
  color: #666;
}

.newss a:hover {
   color: #02b2b5;
}
.title_biaoti {
  text-align: center;
  font-size: 20px;
  color: #333;
  margin: 20px 0 20px 0;
}

</style>
